
.enrollment-footer {
  margin-top: 20px;
  font-size: 11px;
}

.enrollment-login-content-container {
  width: 100%;
}

.enrollment-offer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 50px 100px 100px 100px;
  gap: 16px;
  width: 50%;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;

  @media (max-width: 768px) {
    width: 100%;
    padding: 24px;
    height: 400px;
  }

  h1 {
    height: 122px;
    text-align: left;
    font-size: 50px;
    // color: $color-background;
  }
}

#enrollment-container {
  font-family: 'Bebas Neue';
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px 144px 48px 84px;
  margin-bottom: 0px;
  width: 50%;
  text-align: left;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 100%;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;

  h2 {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-size: 49px;
    line-height: 100%;

    @media (max-width: 768px) {
      font-size: 36px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 24px;
  }

  label {
    width: 100%;
    height: 13px;
    font-family: 'Arial';
    font-weight: 800;
    font-size: 10px;
    margin-top: 5px;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding-top: 5px;
  }

  input {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    gap: 10px;
    width: 100%;
    height: 52px;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
    font-family: 'Arial';
    font-size: 16px;

    &:focus {
      outline: none;
    }
  }
  .checkbox {
    display: flex;
  }
  .checkbox span {
      font-size: inherit;
      font-weight: inherit;

    }
    .checkbox label {
      padding: 9px 0px;
    }

  #enroll-or-sign-in-buttons-container {
    display: flex;
    margin-top: 10px;
    flex-direction: row;
    gap: 10px;
  }


  #enroll-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    font-weight: 700;
    gap: 10px;
    width: 115px;
    height: 56px;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: 'Arial';
    font-size: 16px;
  }
  #sign-in-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    font-weight: 700;
    gap: 10px;
    width: 115px;
    height: 56px;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: 'Arial';
    font-size: 16px;

  }
  


.enrollment-footer {

  a {
    font-weight: 700;
  }
}
}